let main = function ($) {

    let $body = $('body');

    /**
     * Display Menu
     * Affiche / Masque le menu
     */
    let displayMenu = function () {
        let $menu = $('#module> section.main > .menu');

        // Show menu
        $body.on('click', '#module .display-menu', function () {
            $(this).fadeOut(function () {
                $menu.addClass('active').animate({
                    left: 0
                }, 500);

                let documentWidth = $(document).width();
                if (documentWidth < 1200)
                    $body.css({overflowY: 'hidden'});
            });
        });

        // Hide menu
        $body.on('click', '#module .close-menu', function () {
            $menu.removeClass('active').animate({
                left: '-420px'
            }, 500, function () {
                $('#module .display-menu').fadeIn();
            });

            let documentWidth = $(document).width();
            if (documentWidth < 1200)
                $body.css({overflowY: 'auto'});
        });
    };

    /**
     * On resize
     */
    let onResize = function () {
        $(window).on('resize', function (e) {
            // Menu
            let documentWidth = $(document).width();
            let $menu = $('#module> section.main > .menu');

            if (documentWidth < 1200 && $menu.hasClass('active')) {
                if ($menu.hasClass('active'))
                    $body.css({overflowY: 'hidden'});
                if (!$menu.hasClass('active'))
                    $body.css({overflowY: 'auto'});
            }
            if (documentWidth >= 1200)
                $body.css({overflowY: 'auto'});
        });
    };

    return {
        init: function () {
            displayMenu();
            onResize();
        }
    };
}(jQuery);

$(document).ready(function () {
    main.init();
});